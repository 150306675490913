import { Collective_ProfileEducationFragment } from '@collective/data-type';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  IconPlus,
  Spacer,
  Tooltip,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { EducationEmptyState } from './education-empty-state';
import { EducationRow } from './education-row';
import { EducationActions } from './types';

type EducationSectionProps = {
  educations?: Collective_ProfileEducationFragment[];
  actions?: EducationActions;
  isCollapsible: boolean;
};

export const EducationSection = ({
  educations = [],
  actions,
  isCollapsible,
}: EducationSectionProps) => {
  const { t } = useTranslation('common');

  return (
    <Box>
      <Flex alignItems="center" mb="20px">
        <Heading as="h3" variant="desktop-m-bold">
          {t('profile.education.title')}
        </Heading>
        <Spacer />
        {actions && (
          <Flex gap={2}>
            <Tooltip label={t('profile.education.action.add')}>
              <IconButton
                variant="iconButton"
                icon={<IconPlus />}
                aria-label={t('profile.education.action.add')}
                onClick={actions.onCreate}
              />
            </Tooltip>
          </Flex>
        )}
      </Flex>

      {actions && !educations.length && (
        <EducationEmptyState onClick={actions.onCreate} />
      )}

      {educations.map((item) => (
        <EducationRow
          key={item.id}
          education={item}
          actions={actions}
          isCollapsible={isCollapsible}
        />
      ))}
    </Box>
  );
};
