import { useBoolean } from '@collective/ui';
import { RefObject, useCallback, useState } from 'react';

export const useDynamicTextarea = (
  textAreaRef: RefObject<HTMLTextAreaElement>,
  maxHeight: number
) => {
  const [rows, setRows] = useState(1);
  const [hasScrollbar, setHasScrollbar] = useBoolean(false);

  const increaseRow = useCallback(() => {
    setRows((x) => x + 1);
    setHasScrollbar.off();
  }, [setHasScrollbar]);
  const resetRows = useCallback(() => {
    setRows(1);
    setHasScrollbar.off();
  }, [setHasScrollbar]);

  const updateTextareaRows = useCallback(() => {
    if (!textAreaRef.current) {
      return false;
    }

    const style = getComputedStyle(textAreaRef.current);
    const currentHeight = parseInt(style.height, 10);

    if (
      textAreaRef.current.scrollHeight > currentHeight &&
      currentHeight < maxHeight
    ) {
      increaseRow();
    } else if (textAreaRef.current.scrollHeight >= maxHeight) {
      setHasScrollbar.on();
    } else if (textAreaRef.current.scrollHeight < currentHeight) {
      setHasScrollbar.off();
    }
  }, [increaseRow, maxHeight, setHasScrollbar, textAreaRef]);

  return {
    rows,
    increaseRow,
    resetRows,
    hasScrollbar,
    updateTextareaRows,
  };
};
