import {
  Avatar,
  Badge,
  Box,
  Dot,
  Flex,
  Hide,
  Text,
  Tooltip,
} from '@collective/ui';
import { displayName } from '@collective/utils/shared';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ApprovalType } from './types';

type TrustedByRowProps = {
  approval: ApprovalType;
  actions?: ReactNode;
  isTop?: boolean;
  personal?: boolean;
  withSeparator?: boolean;
};

export const TrustedByRow = ({
  approval,
  actions = null,
  isTop = false,
  personal = false,
  withSeparator = false,
}: TrustedByRowProps) => {
  const { t } = useTranslation('frontend');
  const fullname = displayName({
    firstname: approval.firstname,
    lastname: approval.lastname,
  });

  const date = dayjs(approval.approvedDate)
    .format('DD MMMM YYYY')
    .toLowerCase();

  return (
    <Box
      display="flex"
      gap={{ base: 1, md: 3 }}
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      position="relative"
      _after={
        withSeparator
          ? {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: '-12px',
              height: '1px',
              background: 'rythm.300',
            }
          : {}
      }
    >
      <Box
        pr="12px"
        pb={approval.companyLogoUrl ? '12px' : 0}
        flex={{ base: 1, md: 'unset' }}
        order={1}
      >
        <Box position="relative" w="fit-content" h="fit-content">
          <Avatar
            showBorder
            src={approval.pictureUrl}
            name={fullname}
            size="xl"
            borderColor="primary.900"
          />
          {!!approval.companyLogoUrl && (
            <Box position="absolute" bottom="-12px" right="-12px" zIndex={2}>
              <Avatar
                showBorder
                src={approval.companyLogoUrl}
                name={approval.companyName}
                size="sm"
                borderColor="primary.900"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box position="relative" flex={1} order={{ base: 4, md: 2 }}>
        <Flex columnGap={2} flexWrap="wrap" alignItems="center" minH="24px">
          <Text variant="desktop-m-bold">{fullname}</Text>
          <Dot />
          <Text variant="desktop-m-bold" mr={2}>
            {approval.companyName}
          </Text>
          {approval.approvedDate && isTop && (
            <Tooltip label={t('userProfile.trustedBy.topTooltip')}>
              <Badge>{t('userProfile.trustedBy.top')}</Badge>
            </Tooltip>
          )}
          {!approval.approvedDate && (
            <Badge colorScheme="rythm">
              {t('userProfile.trustedBy.pending')}
            </Badge>
          )}
        </Flex>
        <Text variant="desktop-s-regular" color="rythm.700">
          {approval.title}
        </Text>
        {approval.approvedDate && (
          <Text mt={3} color="rythm.700" variant="desktop-s-regular">
            {personal
              ? t('userProfile.trustedBy.personalApprovedOn', {
                  date,
                })
              : t('userProfile.trustedBy.approvedOn', {
                  date,
                })}
          </Text>
        )}
      </Box>
      <Box order={{ base: 2, md: 3 }}>{actions}</Box>
      <Hide above="md" ssr>
        <Box flexBasis="100%" order={3} />
      </Hide>
    </Box>
  );
};
