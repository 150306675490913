import { StyledOptions } from '@emotion/styled';

export const styledIgnoredProps = (
  prevented: string[]
): StyledOptions<string> => ({
  shouldForwardProp: (propName: string) => !prevented.includes(propName),
});

export const isSSR = (): boolean => {
  return typeof window === 'undefined';
};
