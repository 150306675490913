import { stringify } from 'query-string';

const slugPlaceholder = '[slug]';

type GetCollectivePublicPageUrlQuery = {
  collectiveSelection?: string;
  source?: string;
  t?: string;
};

export const getCollectivePublicPageUrl = (
  slug: string,
  query: GetCollectivePublicPageUrlQuery = {},
  path?: string
) => {
  const url = (
    process.env.NX_PUBLIC_PAGE_URL ||
    `http://public.localhost:4201/collective/${slugPlaceholder}`
  ).replace(slugPlaceholder, slug);

  const fullUrl = path ? `${url}/${path}` : url;

  return [fullUrl, stringify(query)].filter(Boolean).join('?');
};

export const getPublicProfileLink = (slug: string) => {
  const base = (
    process.env.NX_PUBLIC_PAGE_URL ||
    `http://public.localhost:4201/collective/${slugPlaceholder}`
  ).replace(`/collective/${slugPlaceholder}`, '/profile');

  return `${base}/${slug}`;
};

export const getPublicJobLink = (slug: string) => {
  const base = (
    process.env.NX_PUBLIC_PAGE_URL ||
    `http://public.localhost:4201/collective/${slugPlaceholder}`
  ).replace(`/collective/${slugPlaceholder}`, '/job');

  return `${base}/${slug}`;
};

export const getCaseStudyPublicPageUrl = (
  collectiveSlug: string,
  caseStudySlug: string
) => {
  const collectivePageUrl = getCollectivePublicPageUrl(collectiveSlug);

  return `${collectivePageUrl}/case-study/${caseStudySlug}`;
};
