import { TFunction } from 'i18next';

import { Language, LanguageLevel } from './types';

type LanguageMapping = Record<Language, string>;
type LanguageLevelMapping = Record<LanguageLevel, string>;

export const getLanguageMapping = (t: TFunction): LanguageMapping => {
  const mapping: LanguageMapping = {
    aa: t('language.aa'),
    ab: t('language.ab'),
    ae: t('language.ae'),
    af: t('language.af'),
    ak: t('language.ak'),
    am: t('language.am'),
    an: t('language.an'),
    ar: t('language.ar'),
    as: t('language.as'),
    av: t('language.av'),
    ay: t('language.ay'),
    az: t('language.az'),
    ba: t('language.ba'),
    be: t('language.be'),
    bg: t('language.bg'),
    bi: t('language.bi'),
    bm: t('language.bm'),
    bn: t('language.bn'),
    bo: t('language.bo'),
    br: t('language.br'),
    bs: t('language.bs'),
    ca: t('language.ca'),
    ce: t('language.ce'),
    ch: t('language.ch'),
    co: t('language.co'),
    cr: t('language.cr'),
    cs: t('language.cs'),
    cu: t('language.cu'),
    cv: t('language.cv'),
    cy: t('language.cy'),
    da: t('language.da'),
    de: t('language.de'),
    dv: t('language.dv'),
    dz: t('language.dz'),
    ee: t('language.ee'),
    el: t('language.el'),
    en: t('language.en'),
    eo: t('language.eo'),
    es: t('language.es'),
    et: t('language.et'),
    eu: t('language.eu'),
    fa: t('language.fa'),
    ff: t('language.ff'),
    fi: t('language.fi'),
    fj: t('language.fj'),
    fo: t('language.fo'),
    fr: t('language.fr'),
    fy: t('language.fy'),
    ga: t('language.ga'),
    gd: t('language.gd'),
    gl: t('language.gl'),
    gn: t('language.gn'),
    gu: t('language.gu'),
    gv: t('language.gv'),
    ha: t('language.ha'),
    he: t('language.he'),
    hi: t('language.hi'),
    ho: t('language.ho'),
    hr: t('language.hr'),
    ht: t('language.ht'),
    hu: t('language.hu'),
    hy: t('language.hy'),
    hz: t('language.hz'),
    ia: t('language.ia'),
    id: t('language.id'),
    ie: t('language.ie'),
    ig: t('language.ig'),
    ii: t('language.ii'),
    ik: t('language.ik'),
    io: t('language.io'),
    is: t('language.is'),
    it: t('language.it'),
    iu: t('language.iu'),
    ja: t('language.ja'),
    jv: t('language.jv'),
    ka: t('language.ka'),
    kg: t('language.kg'),
    ki: t('language.ki'),
    kj: t('language.kj'),
    kk: t('language.kk'),
    kl: t('language.kl'),
    km: t('language.km'),
    kn: t('language.kn'),
    ko: t('language.ko'),
    kr: t('language.kr'),
    ks: t('language.ks'),
    ku: t('language.ku'),
    kv: t('language.kv'),
    kw: t('language.kw'),
    ky: t('language.ky'),
    la: t('language.la'),
    lb: t('language.lb'),
    lg: t('language.lg'),
    li: t('language.li'),
    ln: t('language.ln'),
    lo: t('language.lo'),
    lt: t('language.lt'),
    lu: t('language.lu'),
    lv: t('language.lv'),
    mg: t('language.mg'),
    mh: t('language.mh'),
    mi: t('language.mi'),
    mk: t('language.mk'),
    ml: t('language.ml'),
    mn: t('language.mn'),
    mr: t('language.mr'),
    ms: t('language.ms'),
    mt: t('language.mt'),
    my: t('language.my'),
    na: t('language.na'),
    nb: t('language.nb'),
    nd: t('language.nd'),
    ne: t('language.ne'),
    ng: t('language.ng'),
    nl: t('language.nl'),
    nn: t('language.nn'),
    no: t('language.no'),
    nr: t('language.nr'),
    nv: t('language.nv'),
    ny: t('language.ny'),
    oc: t('language.oc'),
    oj: t('language.oj'),
    om: t('language.om'),
    or: t('language.or'),
    os: t('language.os'),
    pa: t('language.pa'),
    pi: t('language.pi'),
    pl: t('language.pl'),
    ps: t('language.ps'),
    pt: t('language.pt'),
    qu: t('language.qu'),
    rm: t('language.rm'),
    rn: t('language.rn'),
    ro: t('language.ro'),
    ru: t('language.ru'),
    rw: t('language.rw'),
    sa: t('language.sa'),
    sc: t('language.sc'),
    sd: t('language.sd'),
    se: t('language.se'),
    sg: t('language.sg'),
    si: t('language.si'),
    sk: t('language.sk'),
    sl: t('language.sl'),
    sm: t('language.sm'),
    sn: t('language.sn'),
    so: t('language.so'),
    sq: t('language.sq'),
    sr: t('language.sr'),
    ss: t('language.ss'),
    st: t('language.st'),
    su: t('language.su'),
    sv: t('language.sv'),
    sw: t('language.sw'),
    ta: t('language.ta'),
    te: t('language.te'),
    tg: t('language.tg'),
    th: t('language.th'),
    ti: t('language.ti'),
    tk: t('language.tk'),
    tl: t('language.tl'),
    tn: t('language.tn'),
    to: t('language.to'),
    tr: t('language.tr'),
    ts: t('language.ts'),
    tt: t('language.tt'),
    tw: t('language.tw'),
    ty: t('language.ty'),
    ug: t('language.ug'),
    uk: t('language.uk'),
    ur: t('language.ur'),
    uz: t('language.uz'),
    ve: t('language.ve'),
    vi: t('language.vi'),
    vo: t('language.vo'),
    wa: t('language.wa'),
    wo: t('language.wo'),
    xh: t('language.xh'),
    yi: t('language.yi'),
    yo: t('language.yo'),
    za: t('language.za'),
    zh: t('language.zh'),
    zu: t('language.zu'),
  };

  return mapping;
};

export const getLanguageLevelMapping = (t: TFunction): LanguageLevelMapping => {
  return {
    BASIC: t('languageLevel.basic'),
    INTERMEDIATE: t('languageLevel.intermediate'),
    FLUENT: t('languageLevel.fluent'),
    NATIVE: t('languageLevel.native'),
  };
};

export const getLanguageLabel = (t: TFunction, code: Language) => {
  const mapping = getLanguageMapping(t);

  return mapping[code];
};

export const getLanguageLevelLabel = (t: TFunction, code: LanguageLevel) => {
  const mapping = getLanguageLevelMapping(t);

  return mapping[code];
};
