import { isSSR } from '@collective/ui';
import { getLogger } from '@collective/utils/frontend';
import { get, partialRight } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

// Coming from https://usehooks.com/useLocalStorage/
// And adapted for the sessionStorage + with event to sync

const SYNC_EVENT_NAME = 'sessionStorageUpdate';

type ValueOrFunctionValue<T> = T | ((previousData: T) => T);

/**
 * The hook of react use doesn't work properly
 * My guess is :
 * - They have a useEffect without array dependency, resulting to use an old value
 * - The set of the value is done in the useEffect
 * - When we use multiple hook for the same key on the same page, if we update
 * the children, it won't synchronize the parent. And for some reason we got the issue
 * of the parent hook removing the data at some point
 * (probably because the useEffect is triggered and it doesn't have the "new" value set in the children)
 *
 * This hook erases this problem and all the hook on a same key will be synchronized,
 * so their states have the same value.
 * It's useful if you use the session storage with the same key in a parent and a children
 * Otherwise you can use either this hook, either the one of react-use, it will work for simple use-case
 */
export function useSessionStorage<T>(
  key: string,
  initialValue: T
): [T, (value: ValueOrFunctionValue<T>) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (isSSR()) {
      return initialValue;
    }
    try {
      // Get from session storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      getLogger().warnWithMsg(
        "Can't parse the existing item in the session storage. JSON malformed.",
        error
      );
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that
  // persists the new value to sessionStorage
  const setValue = useCallback(
    (value: ValueOrFunctionValue<T>, fromEvent = false) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to session storage
        if (!isSSR()) {
          window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
        }

        // This condition is to avoid a loop of events
        // https://github.com/Collective-work/Collective/pull/2921#discussion_r1150190248
        if (!fromEvent) {
          const updateEvent = new CustomEvent(SYNC_EVENT_NAME, {
            detail: { key, value: valueToStore },
          });

          window.dispatchEvent(updateEvent);
        }
      } catch (error) {
        getLogger().warnWithMsg(
          "Can't set the item in the session storage",
          error
        );
      }
    },
    [key, storedValue]
  );

  // This is to synchronize multiple session storage on the same key
  useEffect(() => {
    const onSessionStorageUpdate = (event: Event) => {
      if (isCustomSyncEvent<T>(event, key)) {
        setValue(event.detail.value, true);
      }
    };

    window.addEventListener(SYNC_EVENT_NAME, onSessionStorageUpdate);

    return () => {
      return window.removeEventListener(
        SYNC_EVENT_NAME,
        onSessionStorageUpdate
      );
    };
  }, [key, setValue]);

  // For SSR
  if (isSSR()) {
    return [initialValue as T, () => {}];
  }

  // We hide the second parameter from this hook API so you can never
  // say it comes from an event by yourself
  const partialSetValue = partialRight(setValue, false);

  return [storedValue, partialSetValue];
}

function isCustomSyncEvent<T>(
  event: Event,
  key: string
): event is CustomEvent<{ key: string; value: T }> {
  return (
    get(event, ['detail', 'value']) && key === get(event, ['detail', 'key'])
  );
}
