import {
  getLanguageLevelMapping,
  getLanguageMapping,
  Language,
  LanguageLevel,
} from '@collective/utils/shared';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguageTranslate = () => {
  const { t } = useTranslation('common');

  const languageMapping = useMemo(() => getLanguageMapping(t), [t]);

  return {
    languageTranslate: useCallback(
      (x: string) => languageMapping[x as Language] || '',
      [languageMapping]
    ),
    languageMapping,
  };
};

export const useLanguageLevelTranslate = () => {
  const { t } = useTranslation('common');

  const languageLevelMapping = useMemo(() => getLanguageLevelMapping(t), [t]);

  return {
    languageLevelTranslate: useCallback(
      (x: string) => languageLevelMapping[x as LanguageLevel] || '',
      [languageLevelMapping]
    ),
    languageLevelMapping,
  };
};
