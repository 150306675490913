import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useState } from 'react';

import { isSSR } from '../utils';

const IsomorphicFontFaceObserver = !isSSR()
  ? FontFaceObserver
  : class {
      load() {
        return Promise.resolve();
      }
    };

export const useFontFaceObserver = (fontFamily: string) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fontObserver = new IsomorphicFontFaceObserver(fontFamily);

    fontObserver
      .load()
      .then(() => {
        setIsLoaded(true);
      })
      .catch(() => {});
  }, [fontFamily]);

  return isLoaded;
};
