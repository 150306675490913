import * as routes from '~/public-pages/constants/routes';
import { formatUrl } from '~/public-pages/router';

const BASE_URL = 'www.collective.work';

export const getCollectivePageUrl = (slug: string): string =>
  `${BASE_URL}/collective/${slug}`;

export const getCaseStudyUrl = (
  collectiveSlug: string,
  caseStudySlug: string
): string =>
  `${BASE_URL}${formatUrl({
    pathname: routes.CASE_STUDY_PATH,
    query: { collectiveSlug, caseStudySlug },
  })}`;

export const getIsMarketplacePage = (pathname: string | undefined) =>
  pathname?.startsWith(`/${routes.COLLECTIVE}/${routes.MARKETPLACE}`);
