import type {
  RangeSliderInnerTrackProps,
  RangeSliderMarkProps,
  RangeSliderProps as ChakraRangeSliderProps,
  RangeSliderThumbProps,
  RangeSliderTrackProps,
  UseRangeSliderProps,
  UseRangeSliderReturn,
} from '@chakra-ui/react';
import {
  RangeSlider as ChakraRangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderProvider,
  RangeSliderThumb,
  RangeSliderTrack,
  useRangeSlider,
  useRangeSliderContext,
  useRangeSliderStyles,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

export {
  ChakraRangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderProvider,
  RangeSliderThumb,
  RangeSliderTrack,
  useRangeSlider,
  useRangeSliderContext,
  useRangeSliderStyles,
};
export type {
  ChakraRangeSliderProps,
  RangeSliderInnerTrackProps,
  RangeSliderMarkProps,
  RangeSliderThumbProps,
  RangeSliderTrackProps,
  UseRangeSliderProps,
  UseRangeSliderReturn,
};

export type RangeSliderProps = ChakraRangeSliderProps & {
  children?: ReactNode;
};

export const RangeSlider = ({
  children,
  size = 'sm',
  ...rest
}: RangeSliderProps) => {
  return (
    <ChakraRangeSlider size={size} {...rest}>
      <RangeSliderTrack background="rythm.300">
        <RangeSliderFilledTrack background="primary.600" />
      </RangeSliderTrack>
      <RangeSliderThumb
        background="primary.300"
        w="16px"
        h="16px"
        borderColor="primary.600"
        borderWidth="1px"
        index={0}
      />
      <RangeSliderThumb
        background="primary.300"
        w="16px"
        h="16px"
        borderColor="primary.600"
        borderWidth="1px"
        index={1}
      />
      {children}
    </ChakraRangeSlider>
  );
};
