import { PublicPages_GetCollective } from '@collective/data-type';
import { AspectRatio, RichTextEditorView } from '@collective/ui';
import { BorderBox, Box, Flex } from '@collective/ui/lib/layout';
import { Heading, Text } from '@collective/ui/lib/typography';
import {
  ImageWrapped,
  PoweredBy,
  PublicPageFooter,
} from '@collective/ui-smart';
import { isEmptyRichTextValue } from '@collective/utils/helpers';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { CaseStudiesSection } from '~/public-pages/components/collective-page/case-studies-section';
import { CollectiveBanner } from '~/public-pages/components/collective-page/collective-banner';
import { MembersSection } from '~/public-pages/components/collective-page/members-section';
import { MembersSideCard } from '~/public-pages/components/collective-page/members-side-card';
import { ServicesSection } from '~/public-pages/components/collective-page/services-section';
import { ContactCollectiveButton } from '~/public-pages/components/contact/contact-collective-button';
import { useHasCollectiveSelection } from '~/public-pages/hooks/use-has-collective-selection';
import { getIsMarketplacePage } from '~/public-pages/utils/urls';

type CollectivePublicPageProps = {
  collective: NonNullable<PublicPages_GetCollective['collective']>;
};

export const CollectivePublicPage = ({
  collective,
}: CollectivePublicPageProps) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const isMarketplacePage = getIsMarketplacePage(pathname);
  const hasCollectiveSelection = useHasCollectiveSelection();

  const allMembers = [...collective.members, ...collective.membersInvited].sort(
    (a, b) => {
      return (a.ranking ?? 0) - (b.ranking ?? 0);
    }
  );

  return (
    <Flex direction="column" h="100%">
      <Box
        flex={1}
        pb={{
          base: '75px',
          sm: '200px',
        }}
        bgColor="rythm.100"
        overflowY="auto"
      >
        <CollectiveBanner coverUrl={collective.coverUrl} />
        <Flex
          mt={{
            base: '-20px',
            md: '-40px',
            '4xl': '-70px',
          }}
          align="start"
          justify="center"
          px="16px"
          gap="32px"
        >
          <Box flex={1} maxW="736px" w="max(240px, 100%)">
            <BorderBox
              noHover
              mb={10}
              display="flex"
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems="start"
              gap="16px"
              p="20px"
            >
              {collective.logoUrl && (
                <AspectRatio
                  ratio={1}
                  w={{ base: '60px', sm: '100px' }}
                  flexShrink={0}
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="rythm.200"
                  overflow="hidden"
                >
                  <ImageWrapped
                    src={collective.logoUrl}
                    alt={`${collective.name} logo`}
                    srcSetSizes={['100px']}
                    withSkeleton
                  />
                </AspectRatio>
              )}
              <Box>
                <Heading variant="desktop-xl-bold" mb={2}>
                  {collective.name}
                </Heading>
                <Text variant="desktop-l-regular">{collective.tagline}</Text>
              </Box>
            </BorderBox>

            <CaseStudiesSection
              key={collective.id} // Key is here so that the carousel resets when changing collective in the marketplace
              slug={collective.slug}
              caseStudies={collective.caseStudies}
              mb={10}
            />

            <MembersSection allMembers={allMembers} mb={10} />

            <ServicesSection services={collective.offeredServices} mb={10} />

            {!isEmptyRichTextValue(collective.description) && (
              <Box>
                <Heading variant="desktop-l-bold" mb={4}>
                  {t('collectivePage.descriptionSectionTitle')}
                </Heading>
                <RichTextEditorView
                  content={collective.description ?? ''}
                  data-chromatic="ignore"
                />
              </Box>
            )}

            <Box>
              <PoweredBy />
            </Box>
          </Box>

          <MembersSideCard
            allMembers={allMembers}
            collectiveSlug={collective.slug}
            display={{ base: 'none', xl: 'block' }}
            position="sticky"
            top="40px"
          />
        </Flex>
      </Box>

      {!hasCollectiveSelection && (
        <PublicPageFooter display={{ base: 'flex', xl: 'none' }}>
          <Text variant="desktop-m-bold">{t('contactSection.info')}</Text>
          <ContactCollectiveButton collectiveSlug={collective.slug}>
            {isMarketplacePage
              ? t('collectivePage.contactButton.onMarketplace')
              : t('collectivePage.contactButton.onCollectivePage')}
          </ContactCollectiveButton>
        </PublicPageFooter>
      )}
    </Flex>
  );
};
