import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  Text,
  Tooltip,
} from '@collective/ui';
import { ALL_IDENTIFIER } from '@collective/utils/frontend';
import { isPresent } from '@collective/utils/helpers';
import { getLinkHref } from '@collective/utils/shared';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../../highlighting/highlight-text';
import { getLinkIcon } from '../../utils/links';
import { UserProfileLink } from '../types';
import { ProfileBox } from './profile-box';
import { OnLinkClick, OpportunityMember, RenderLink } from './types';

type SoloApplicationAboutSectionProps = {
  links: UserProfileLink[];
  member: OpportunityMember;
  onLinkClick: OnLinkClick;
  renderLink?: RenderLink;
};

export const SoloApplicationAboutSection = ({
  member,
  links,
  onLinkClick,
  renderLink,
}: SoloApplicationAboutSectionProps) => {
  const { t } = useTranslation('common');

  const { intro, linkedInUrl, resumeUrl } = member;

  if (!intro && !linkedInUrl && !resumeUrl && !links.length) {
    return null;
  }

  return (
    <ProfileBox>
      <Box>
        <Heading as="h3" variant="desktop-m-bold" mb={5}>
          {t('profile.application.about.header')}
        </Heading>

        <Flex direction="column" gap="24px">
          <IntroSection intro={intro} />
          <LinksSection
            member={member}
            links={links}
            onLinkClick={onLinkClick}
            renderLink={renderLink}
          />
        </Flex>
      </Box>
    </ProfileBox>
  );
};

type IntroSectionProps = {
  intro: string | null | undefined;
};

const IntroSection = ({ intro }: IntroSectionProps) => {
  const { t } = useTranslation('common');

  if (!intro) {
    return null;
  }

  return (
    <Box>
      <Text color="rythm.700" variant="desktop-s-semibold" mb={1}>
        {t('profile.application.about.intro')}
      </Text>

      <HighlightText identifiers={[ALL_IDENTIFIER]}>
        <Text whiteSpace="pre-wrap">{intro}</Text>
      </HighlightText>
    </Box>
  );
};

type LinksSectionProps = {
  member: OpportunityMember;
  links: UserProfileLink[];
  onLinkClick: OnLinkClick;
  renderLink?: RenderLink;
};

const LinksSection = ({
  member,
  links,
  onLinkClick,
  renderLink = defaultRenderLink,
}: LinksSectionProps) => {
  const { t } = useTranslation('common');

  const { linkedInUrl } = member;

  if (!linkedInUrl && !links.length) {
    return null;
  }

  const data: UserProfileLink[] = [
    linkedInUrl
      ? {
          id: 'linkedInUrl',
          name: t('profile.application.links.linkedIn'),
          url: linkedInUrl,
          onLinkClick,
        }
      : null,
    ...links,
  ].filter(isPresent);

  return (
    <Box>
      <Text color="rythm.700" variant="desktop-s-semibold" mb={1}>
        {t('profile.application.about.links')}
      </Text>
      <Flex
        w="fit-content"
        align={{ base: 'center', md: 'flex-start' }}
        gap="8px"
      >
        {data.map(({ id, name, url }) => {
          return renderLink({ id, name, url, onLinkClick });
        })}
      </Flex>
    </Box>
  );
};

const defaultRenderLink: RenderLink = ({ id, name, url, onLinkClick }) => {
  const Icon = getLinkIcon(url);
  const href = getLinkHref(url);

  return (
    <Tooltip label={name} key={id}>
      <Link
        href={href}
        textDecoration="none"
        target="_blank"
        onClick={(e) => onLinkClick(url, e)}
        onAuxClick={(e) => onLinkClick(url, e)}
      >
        <IconButton variant="naked" icon={<Icon />} aria-label={name} />
      </Link>
    </Tooltip>
  );
};
