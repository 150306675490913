import {
  AvailabilityStatus,
  Client_FullMemberFragment,
  Collective_GetNetworkUser,
  Collective_SearchUsers,
  PublicPages_GetPublicUser,
  Recruiter_SearchUsers,
  WorkPreference,
} from '@collective/data-type';
import {
  Box,
  Flex,
  IconCheck,
  IconCompany,
  IconMapPin,
  IconMoney,
  IconRocket,
  IconThumbUp,
  Text,
  Tooltip,
} from '@collective/ui';
import {
  ArrayElement,
  displayCurrencyFromNumber,
} from '@collective/utils/helpers';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { UserPill } from './user-pill';

type RecruiterUser =
  | Omit<
      ArrayElement<Recruiter_SearchUsers['results']['users']>,
      'isImported' | 'crmCounter'
    >
  | ArrayElement<Collective_SearchUsers['results']['users']>;
type NetworkUser = Collective_GetNetworkUser['user'];
type PublicProfileUser = PublicPages_GetPublicUser['user'];
type ClientUser = Client_FullMemberFragment['user'] & {
  availabilityValidUntil?: never;
  availabilityStatus?: never;
};

type UserType = NonNullable<
  ClientUser | RecruiterUser | NetworkUser | PublicProfileUser
>;

type UserPillsProps = {
  user: UserType;
};

export const UserPills = ({ user }: UserPillsProps) => {
  const { t } = useTranslation('common');

  return (
    <Flex mt={5} gap={2} flexWrap="wrap" _empty={{ mt: 0 }}>
      {!!user.dailyRate && (
        <UserPill
          icon={<IconMoney color="primary.500" size="sm" />}
          content={
            <Trans
              i18nKey="profile.tags.dailyRate"
              components={{
                dark: <Text variant="desktop-m-medium" as="span" />,
              }}
              values={{
                rate: displayCurrencyFromNumber(user.dailyRate),
              }}
              ns="common"
            />
          }
          colorScheme="blue"
        />
      )}
      <AvailabilityPill user={user} />
      {!!user.approvalCount && (
        <UserPill
          icon={<IconThumbUp color="decorative.sky.500" size="xs" />}
          content={
            <Trans
              ns="common"
              i18nKey="profile.tags.approvedBy"
              components={{
                skyBlue: (
                  <Text
                    color="decorative.sky.500"
                    variant="desktop-m-medium"
                    as="span"
                  />
                ),
              }}
              values={{
                count: user.approvalCount,
                total: user.approvalCount > 20 ? '20+' : user.approvalCount,
              }}
            />
          }
          colorScheme="sky"
        />
      )}
      {!!user.missionCount && (
        <UserPill
          icon={<IconRocket color="rythm.600" size="xs" />}
          content={t('profile.tags.missionAchieved', {
            count: user.missionCount,
          })}
          colorScheme="basic"
        />
      )}
      {user.workLocation && (
        <UserPill
          icon={<IconMapPin color="rythm.600" size="xs" />}
          content={user.workLocation}
          colorScheme="basic"
        />
      )}
      {user.workPreference && (
        <UserPill
          icon={<IconCompany color="rythm.600" size="sm" />}
          content={getWorkPreferenceLabel(t, user.workPreference)}
          colorScheme="basic"
        />
      )}
    </Flex>
  );
};

const AvailabilityPill = ({ user }: UserPillsProps) => {
  const { t } = useTranslation('common');

  const { availabilityStatus } = user;

  if (
    availabilityStatus &&
    [AvailabilityStatus.ReadyForInterview].includes(availabilityStatus)
  ) {
    return (
      <Tooltip label={t('profile.tags.readyToInterviewTooltip')}>
        <Box>
          <UserPill
            icon={<IconCheck size="xs" />}
            content={t('profile.tags.readyToInterview')}
            colorScheme="green"
          />
        </Box>
      </Tooltip>
    );
  }

  return null;
};

const getWorkPreferenceLabel = (
  t: TFunction,
  workPreference: WorkPreference | null | undefined
) => {
  switch (workPreference) {
    case WorkPreference.Flexible:
      return t('profile.tags.hybrid');
    case WorkPreference.Remote:
      return t('profile.tags.remoteOnly');
    default:
      return null;
  }
};
