import { TextProps } from '@collective/ui';

export const tabProps: TextProps = {
  pb: '8px',
  borderBottom: '2px solid',
  borderBottomColor: 'white',
  borderX: '2px solid',
  borderLeftColor: 'white',
  borderRightColor: 'white',
  textDecoration: 'none',
  color: 'rythm.700',
  _hover: { color: 'rythm.900' },
  cursor: 'pointer',
};

export const activeTabProps: TextProps = {
  ...tabProps,
  borderBottomColor: 'primary.600',
  color: 'rythm.900',
};

export enum SoloApplicationMenus {
  NEED = 'your-needs',
  PORTFOLIO = 'portfolio',
  WORK_EXPERIENCE = 'work-experience',
  EDUCATION = 'education',
}

export const DISPLAYED_TOP_APPROVALS_COUNT = 3;
