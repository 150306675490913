export const ACCESS_TOKEN_INVALID_ERROR = 'ACCESS_TOKEN_INVALID';
export const ADMIN_NOT_FOUND_ERROR = 'ADMIN_NOT_FOUND';
export const PROJECT_MISSING_ADMIN_ERROR = 'PROJECT_MISSING_ADMIN_ERROR';
export const AUTHENTICATION_ERROR = 'UNAUTHENTICATED';
export const REFRESH_ERROR = 'REFRESH_ERROR';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const BAD_USER_INPUT = 'BAD_USER_INPUT';
export const CANNOT_MARK_AS_REJECTED_BY_CLIENT =
  'CANNOT_MARK_AS_REJECTED_BY_CLIENT';
export const CASE_STUDY_NOT_FOUND = 'CASE_STUDY_NOT_FOUND';
export const CASE_STUDY_ORDER_DATA_INVALID = 'CASE_STUDY_ORDER_DATA_INVALID';
export const CHANNEL_NOT_FOUND = 'CHANNEL_NOT_FOUND';
export const CHANNEL_MESSAGE_NOT_FOUND = 'CHANNEL_MESSAGE_NOT_FOUND';
export const CHANNEL_MESSAGE_LINK_PREVIEW_NOT_FOUND =
  'CHANNEL_MESSAGE_LINK_PREVIEW_NOT_FOUND';
export const CHANNEL_MESSAGE_ATTACHMENT_NOT_FOUND =
  'CHANNEL_MESSAGE_ATTACHMENT_NOT_FOUND';
export const CHANNEL_PARTICIPANT_REQUIRED = 'CHANNEL_PARTICIPANT_REQUIRED';
export const COLLECTIVE_NO_MEMBERS = 'COLLECTIVE_NO_MEMBERS';
export const COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND';
export const COMPANY_LEGAL_ENTITY_NOT_FOUND = 'COMPANY_LEGAL_ENTITY_NOT_FOUND';
export const CREATE_PROPOSAL_MISSING_MILESTONES =
  'CREATE_PROPOSAL_MISSING_MILESTONES';
export const CREATE_PROPOSAL_MISSING_PAY_AS_YOU_MEMBERS =
  'CREATE_PROPOSAL_MISSING_PAY_AS_YOU_GO_MEMBERS';
export const CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND';
export const COLLECTIVE_ACCESS_FORBIDDEN = 'COLLECTIVE_ACCESS_FORBIDDEN';
export const COLLECTIVE_SLUG_INVALID = 'COLLECTIVE_SLUG_INVALID';
export const COLLECTIVE_SLUG_ALREADY_EXISTS = 'COLLECTIVE_SLUG_ALREADY_EXISTS';
export const COLLECTIVE_PAGE_ALREADY_PUBLISHED =
  'COLLECTIVE_PAGE_ALREADY_PUBLISHED';
export const CONTRACT_INVALID = 'CONTRACT_INVALID';
export const PROPOSAL_ACCESS_FORBIDDEN = 'PROPOSAL_ACCESS_FORBIDDEN';
export const PROPOSAL_MILESTONES_OR_CUSTOM_WITH_MISSING_FIELDS =
  'PROPOSAL_MILESTONES_OR_CUSTOM_WITH_MISSING_FIELDS';
export const DELETE_COLLECTIVE_NOT_ALLOWED = 'DELETE_COLLECTIVE_NOT_ALLOWED';
export const DELETE_MEMBER_NOT_ALLOWED = 'DELETE_MEMBER_NOT_ALLOWED';
export const DOCUMENT_ALREADY_SIGNED = 'DOCUMENT_ALREADY_SIGNED';
export const DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND';
export const ELECTRONIC_SIGNATURE_DOCUMENT_CANCELED =
  'ELECTRONIC_SIGNATURE_DOCUMENT_CANCELED';
export const ELECTRONIC_SIGNATURE_DOCUMENT_NOT_FOUND =
  'ELECTRONIC_SIGNATURE_DOCUMENT_NOT_FOUND';
export const FORBIDDEN = 'FORBIDDEN';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const BAD_REQUEST = 'BAD_REQUEST';
export const GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED';
export const OPPORTUNITY_SHARE_NOT_FOUND = 'OPPORTUNITY_SHARE_NOT_FOUND';
export const OPPORTUNITY_SHARE_INVALID_PAYLOAD =
  'OPPORTUNITY_SHARE_INVALID_PAYLOAD';
export const OPPORTUNITY_STATUS_NOT_ALLOWED = 'OPPORTUNITY_STATUS_NOT_ALLOWED';
export const OPPORTUNITY_STATUS_NOT_FOUND = 'OPPORTUNITY_STATUS_NOT_FOUND';
export const OPPORTUNITY_STATUS_COLLECTIVE_REASONS_NOT_FOUND =
  'OPPORTUNITY_STATUS_COLLECTIVE_REASONS_NOT_FOUND';
export const OPPORTUNITY_TOO_MANY_CREATED_COLLECTIVES_SELECTED =
  'OPPORTUNITY_TOO_MANY_CREATED_COLLECTIVES_SELECTED';
export const IBAN_INVALID = 'IBAN_INVALID';
export const NO_NEW_IBAN_SET = 'NO_NEW_IBAN_SET';
export const NETWORK_CONNECTION_ALREADY_EXISTS =
  'NETWORK_CONNECTION_ALREADY_EXISTS';
export const NETWORK_CONNECTION_NOT_ALLOWED = 'NETWORK_CONNECTION_NOT_ALLOWED';
export const NETWORK_CONNECTION_NOT_FOUND = 'NETWORK_CONNECTION_NOT_FOUND';
export const NETWORK_CONNECTION_REQUEST_NOT_FOUND =
  'NETWORK_CONNECTION_REQUEST_NOT_FOUND';
export const INVOICE_NOT_FOUND = 'INVOICE_NOT_FOUND';
export const MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND';
export const MEMBERS_UPDATE_UNEXPECTED_ERROR =
  'MEMBERS_UPDATE_UNEXPECTED_ERROR';
export const MEMBER_INVITED_NOT_FOUND = 'MEMBER_INVITED_NOT_FOUND';
export const MEMBER_INVITED_INVALID = 'MEMBER_INVITED_INVALID';
export const MEMBERS_CONFLICTS = 'MEMBERS_CONFLICTS';
export const PERSONAL_SPACE_NOT_FOUND = 'PERSONAL_SPACE_NOT_FOUND';
export const POST_NOT_FOUND = 'POST_NOT_FOUND';
export const COLLECTIVE_NOT_FOUND = 'COLLECTIVE_NOT_FOUND';
export const COLLECTIVE_NOT_SOLO = 'COLLECTIVE_NOT_SOLO';
export const COLLECTIVE_ALREADY_CHANGED_NAME =
  'COLLECTIVE_ALREADY_CHANGED_NAME';
export const OFFERED_SERVICE_NOT_FOUND = 'OFFERED_SERVICE_NOT_FOUND';
export const OPPORTUNITY_COLLECTIVE_NOT_FOUND =
  'OPPORTUNITY_COLLECTIVE_NOT_FOUND';
export const OPPORTUNITY_NOT_FOUND = 'OPPORTUNITY_NOT_FOUND';
export const OPPORTUNITY_EXPIRED = 'OPPORTUNITY_EXPIRED';
export const OPPORTUNITY_EXPIRATION_DATE = 'OPPORTUNITY_EXPIRATION_DATE';

export const OPPORTUNITY_NOT_ACCEPTED_BY_COLLECTIVE =
  'OPPORTUNITY_NOT_ACCEPTED_BY_COLLECTIVE';
export const OPPORTUNITY_PROJECT_NOT_FOUND = 'OPPORTUNITY_PROJECT_NOT_FOUND';
export const OPPORTUNITY_REMINDER_TOO_MANY_SELECTED =
  'OPPORTUNITY_REMINDER_TOO_MANY_SELECTED';
export const OPPORTUNITY_REMINDER_ALREADY_ANSWERED =
  'OPPORTUNITY_REMINDER_ALREADY_ANSWERED';
export const OPPORTUNITY_REMINDER_EXPIRED_OPPORTUNITIES_SELECTED =
  'OPPORTUNITY_REMINDER_EXPIRED_OPPORTUNITIES_SELECTED';
export const OPPORTUNITY_STATUS_ALREADY_EXISTS =
  'OPPORTUNITY_STATUS_ALREADY_EXISTS';
export const PROJECT_CLIENT_EXISTS = 'PROJECT_CLIENT_EXISTS';
export const PROJECT_CLIENT_INVALID = 'PROJECT_CLIENT_INVALID';
export const PROJECT_CLIENT_NOT_FOUND = 'PROJECT_CLIENT_NOT_FOUND';
export const PROJECT_COMPANY_NOT_FOUND = 'PROJECT_COMPANY_NOT_FOUND';
export const PROJECT_ESTIMATED_BUDGET_INVALID =
  'PROJECT_ESTIMATED_BUDGET_INVALID';
export const PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND';
export const PROJECT_NOT_READY_TO_CLOSE = 'PROJECT_NOT_READY_TO_CLOSE';
export const PROJECT_HAS_ALREADY_AN_ACCEPTED_PROPOSAL =
  'PROJECT_HAS_ALREADY_AN_ACCEPTED_PROPOSAL';
export const PROJECT_OPPORTUNITIES_ACCEPTED_BY_CLIENT_NOT_FOUND =
  'PROJECT_OPPORTUNITIES_ACCEPTED_BY_CLIENT_NOT_FOUND';
export const PROJECT_FEEDBACK_ALREADY_SUBMITTED =
  'PROJECT_FEEDBACK_ALREADY_SUBMITTED';
export const PROJECT_POSITION_NOT_FOUND = 'PROJECT_POSITION_NOT_FOUND';
export const PROJECT_TYPES_LIMIT_EXCEEDED = 'PROJECT_TYPES_LIMIT_EXCEEDED';
export const OPPORTUNITY_CASE_STUDIES_LIMIT_EXCEEDED =
  'OPPORTUNITY_CASE_STUDIES_LIMIT_EXCEEDED';
export const PROPOSAL_NOT_FOUND = 'PROPOSAL_NOT_FOUND';
export const PROPOSAL_EXPIRED = 'PROPOSAL_EXPIRED';
export const PROPOSAL_INVALID_STATUS = 'PROPOSAL_INVALID_STATUS';
export const PROPOSAL_INVALID_CONTRACT_DATES =
  'PROPOSAL_INVALID_CONTRACT_DATES';
export const PROPOSAL_NOT_A_CONTRACT = 'PROPOSAL_NOT_A_CONTRACT';
export const PROPOSAL_PROJECT_NOT_FOUND = 'PROPOSAL_PROJECT_NOT_FOUND';
export const PROPOSAL_UPDATE_FORBIDDEN = 'PROPOSAL_UPDATE_FORBIDDEN';
export const USER_ALREADY_SIGNED_UP = 'USER_ALREADY_SIGNED_UP';
export const USER_ALREADY_EXISTS_CLIENT_CREATION =
  'USER_ALREADY_EXISTS_CLIENT_CREATION';
export const USER_BIRTHDATE_MISSING = 'USER_BIRTHDATE_MISSING';
export const USER_COMPANY_NOT_FOUND = 'USER_COMPANY_NOT_FOUND';
export const USER_COMPANY_ALREADY_REGISTERED =
  'USER_COMPANY_ALREADY_REGISTERED';
export const USER_COMPANY_MISSING_INFO = 'USER_COMPANY_MISSING_INFO';
export const USER_COMPANY_NO_LEGAL_STRUCTURE =
  'USER_COMPANY_NO_LEGAL_STRUCTURE';
export const USER_COMPANY_INVALID_STRUCTURE = 'USER_COMPANY_INVALID_STRUCTURE';
export const USER_COMPANY_SEARCH_FAILED = 'USER_COMPANY_SEARCH_FAILED';
export const USER_CURRENT_PASSWORD_INVALID = 'USER_CURRENT_PASSWORD_INVALID';
export const USER_EMAIL_ALREADY_USED = 'USER_EMAIL_ALREADY_USED';
export const USER_EMAIL_LINK_INVALID = 'USER_EMAIL_LINK_INVALID';
export const USER_COMPANY_IBAN_VERIFICATION_LINK_INVALID =
  'USER_COMPANY_IBAN_VERIFICATION_LINK_INVALID';
export const USER_NEW_PASSWORD_INVALID = 'USER_NEW_PASSWORD_INVALID';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';

export const USER_AVAILABILITY_OUT_OF_RANGE = 'USER_AVAILABILITY_OUT_OF_RANGE';

export const USER_VETTING_NO_BLACKLIST_ALLOWED_WHEN_STATUS_IS_VETTED_ERROR =
  'USER_VETTING_NO_BLACKLIST_ALLOWED_WHEN_STATUS_IS_VETTED_ERROR';
export const USER_VETTING_BLACKLIST_REASON_OTHER_NOT_FOUND_ERROR =
  'USER_VETTING_BLACKLIST_REASON_OTHER_NOT_FOUND_ERROR';
export const USER_VETTING_BLACKLIST_REASON_NOT_FOUND_ERROR =
  'USER_VETTING_BLACKLIST_REASON_NOT_FOUND_ERROR';
export const VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID';
export const USER_ALREADY_PART_OF_THIS_COLLECTIVE =
  'USER_ALREADY_PART_OF_THIS_COLLECTIVE';
export const USER_ONBOARDING_INVALID = 'USER_ONBOARDING_INVALID';
export const PROFILE_ALREADY_POPULATED = 'PROFILE_ALREADY_POPULATED';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const PROFILE_EDUCATION_NOT_FOUND = 'PROFILE_EDUCATION_NOT_FOUND';
export const PROFILE_WORK_EXPERIENCE_NOT_FOUND =
  'PROFILE_WORK_EXPERIENCE_NOT_FOUND';
export const PROJECT_ADMIN_NOT_FOUND = 'PROJECT_ADMIN_NOT_FOUND';
export const PROPOSAL_ALREADY_CONTRACTED = 'PROPOSAL_ALREADY_CONTRACTED';
export const OPPORTUNITY_COLLECTIVE_NAME_NOT_FOUND =
  'OPPORTUNITY_COLLECTIVE_NAME_NOT_FOUND';
export const MISSION_NOT_FOUND = 'MISSION_NOT_FOUND';
export const MISSION_ENDED = 'MISSION_ENDED';
export const MISSION_ALREADY_EXISTS = 'MISSION_ALREADY_EXISTS';
export const PAYMENT_REQUEST_MILESTONE_ALREADY_EXISTS =
  'PAYMENT_REQUEST_MILESTONE_ALREADY_EXISTS';
export const MILESTONE_NOT_FOUND = 'MILESTONE_NOT_FOUND';
export const MILESTONE_INVALID = 'MILESTONE_INVALID';
export const MILESTONE_MEMBER_NOT_IN_PROPOSAL_MEMBERS =
  'MILESTONE_MEMBER_NOT_IN_PROPOSAL_MEMBERS';
export const PROPOSAL_MEMBER_NOT_IN_COLLECTIVE =
  'PROPOSAL_MEMBER_NOT_IN_COLLECTIVE';
export const MISSING_PROPOSAL_MEMBERS = 'MISSING_PROPOSAL_MEMBERS';
export const PAY_AS_YOU_GO_MEMBER_NOT_IN_PROPOSAL_MEMBERS =
  'PAY_AS_YOU_GO_MEMBER_NOT_IN_PROPOSAL_MEMBERS';
export const PAY_AS_YOU_GO_MEMBERS_NOT_FOUND_ERROR =
  'PAY_AS_YOU_GO_MEMBERS_NOT_FOUND_ERROR';
export const PAYMENT_REQUEST_REMINDER = 'PAYMENT_REQUEST_REMINDER';
export const PAYMENT_REQUEST_NOT_FOUND = 'PAYMENT_REQUEST_NOT_FOUND';
export const PAYMENT_REQUEST_CANCEL_SCHEDULED =
  'PAYMENT_REQUEST_CANCEL_SCHEDULED';
export const PAYMENT_REQUEST_CANCEL_PAID_INVOICES =
  'PAYMENT_REQUEST_CANCEL_PAID_INVOICES';
export const PAYMENT_REQUEST_CANCEL_NO_INVOICE =
  'PAYMENT_REQUEST_CANCEL_NO_INVOICE';
export const PAYMENT_REQUEST_INVALID = 'PAYMENT_REQUEST_INVALID';
export const PAYMENT_REQUEST_SCHEDULE_NOT_FOUND =
  'PAYMENT_REQUEST_SCHEDULE_NOT_FOUND';
export const PAYMENT_REQUEST_PAY_AS_YOU_GO_MEMBER_NOT_FOUND =
  'PAYMENT_REQUEST_PAY_AS_YOU_GO_MEMBER_NOT_FOUND';
export const PAYMENT_REQUEST_NO_CONTRACT_CLIENT_ITEMS_NOT_FOUND =
  'PAYMENT_REQUEST_NO_CONTRACT_CLIENT_ITEMS_NOT_FOUND';
export const PAYMENT_REQUEST_NO_CONTRACT_MEMBERS_ITEMS_NOT_FOUND =
  'PAYMENT_REQUEST_NO_CONTRACT_MEMBERS_ITEMS_NOT_FOUND';
export const PAYMENT_REQUEST_ALREADY_APPROVED =
  'PAYMENT_REQUEST_ALREADY_APPROVED';
export const PAYMENT_REQUEST_PAY_AS_YOU_GO_MEMBERS_INPUT_BAD_COUNT =
  'PAYMENT_REQUEST_PAY_AS_YOU_GO_MEMBERS_INPUT_BAD_COUNT';
export const PAYMENT_REQUEST_PAY_AS_YOU_GO_MEMBER_MEMBER_ID_NOT_IN_PROPOSAL =
  'PAYMENT_REQUEST_PAY_AS_YOU_GO_MEMBER_MEMBER_ID_NOT_IN_PROPOSAL';
export const MEMBER_INVOICES_ALREADY_GENERATED =
  'MEMBER_INVOICES_ALREADY_GENERATED';
export const MEMBER_INVOICES_NOT_YET_GENERATED =
  'MEMBER_INVOICES_NOT_YET_GENERATED';
export const CLIENT_INVOICE_REQUIRED = 'CLIENT_INVOICE_REQUIRED';
export const FILE_TOO_LARGE = 'FILE_TOO_LARGE';
export const PAYMENT_REQUEST_NOT_APPROVED = 'PAYMENT_REQUEST_NOT_APPROVED';
export const PAYMENT_REQUEST_MISSING_APPROVAL_DATE_ERROR =
  'PAYMENT_REQUEST_MISSING_APPROVAL_DATE_ERROR';
export const CLIENT_LANGUAGE_NOT_FOUND = 'CLIENT_LANGUAGE_NOT_FOUND';
export const INVOICE_STATUS_ALREADY_EXISTS = 'INVOICE_STATUS_ALREADY_EXISTS';
export const INVOICE_CANCELLED_ERROR = 'INVOICE_CANCELLED_ERROR';
export const INVOICE_STATUS_NOT_ALLOWED = 'INVOICE_STATUS_NOT_ALLOWED';
export const JOB_NOT_FOUND = 'JOB_NOT_FOUND';
export const JOB_ALREADY_CLOSED = 'JOB_ALREADY_CLOSED';
export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
export const INVALID_URL_ERROR = 'INVALID_URL_ERROR';
export const INVALID_TOKEN_ERROR = 'INVALID_TOKEN_ERROR';
export const FILE_FORMAT_INVALID_ERROR = 'FILE_FORMAT_INVALID_ERROR';
export const QUOTE_NOT_FOUND = 'QUOTE_NOT_FOUND';
export const QUOTE_REQUESTED_ELECTRONIC_SIGNATURE =
  'QUOTE_REQUESTED_ELECTRONIC_SIGNATURE';
export const CANNOT_DELETE_QUOTE = 'CANNOT_DELETE_QUOTE';
// Errors for Google SSO
export const GOOGLE_SSO_USER_NOT_FOUND = 'GOOGLE_SSO_USER_NOT_FOUND';
export const GOOGLE_SSO_INVALID_EMAIL = 'GOOGLE_SSO_INVALID_EMAIL';
export const GOOGLE_SSO_UNKNOWN_ERROR = 'GOOGLE_SSO_UNKNOWN_ERROR';
export const GOOGLE_SSO_STATE_INVALID = 'GOOGLE_SSO_STATE_INVALID';
export const MEMBER_ALREADY_MARKED_AS_DELETED_ERROR =
  'MEMBER_ALREADY_MARKED_AS_DELETED_ERROR';
export const USER_ALREADY_MARKED_AS_DELETED_ERROR =
  'USER_ALREADY_MARKED_AS_DELETED_ERROR';
export const COLLECTIVE_ALREADY_MARKED_AS_DELETED_ERROR =
  'COLLECTIVE_ALREADY_MARKED_AS_DELETED_ERROR';
export const PROPOSAL_PAYMENT_OPTION_NOT_FOUND_ERROR =
  'PROPOSAL_DOCUMENT_NOT_FOUND_ERROR';
export const COMPANY_ALREADY_LINKED_TO_LEGAL_ENTITY_ERROR =
  'COMPANY_ALREADY_LINKED_TO_LEGAL_ENTITY_ERROR';
export const COMPANY_LEGAL_ENTITY_MISSING_INFO =
  'COMPANY_LEGAL_ENTITY_MISSING_INFO';
export const PROJECT_HAS_ALREADY_A_CONTRACT = 'PROJECT_HAS_ALREADY_A_CONTRACT';
export const URL_NOT_FOUND_ERROR = 'URL_NOT_FOUND_ERROR';
export const COLLECTIVE_CONTACT_NOT_FOUND = 'COLLECTIVE_CONTACT_NOT_FOUND';
export const UNIQUE_REFERENCE_GENERATION_FAILED_ERROR =
  'UNIQUE_REFERENCE_GENERATION_FAILED_ERROR';
export const COLLECTIVE_CONTACT_INVALID = 'COLLECTIVE_CONTACT_INVALID';
export const NET_AMOUNT_BIGGER_THAN_GROSS_AMOUNT_ERROR =
  'NET_AMOUNT_BIGGER_THAN_GROSS_AMOUNT_ERROR';
export const OPERATION_NOT_ALLOWED_ON_DISINTERMEDIATED_MISSION_ERROR =
  'OPERATION_NOT_ALLOWED_ON_DISINTERMEDIATED_MISSION_ERROR';
export const OPERATION_NOT_ALLOWED_ON_INTERMEDIATED_MISSION_ERROR =
  'OPERATION_NOT_ALLOWED_ON_INTERMEDIATED_MISSION_ERROR';
export const PAYMENT_PROVIDER_ERROR = 'PAYMENT_PROVIDER_ERROR';
export const COLLECTIVES_WITHOUT_CLIENT_ANSWER_ERROR =
  'COLLECTIVES_WITHOUT_CLIENT_ANSWER_ERROR';
export const INVALID_FILE_FORMAT = 'INVALID_FILE_FORMAT';
export const USER_WITHOUT_COLLECTIVE = 'USER_WITHOUT_COLLECTIVE';
export const RATE_LIMIT_ERROR = 'RATE_LIMIT_ERROR';
export const OPPORTUNITY_NOTE_NOT_FOUND = 'OPPORTUNITY_NOTE_NOT_FOUND';
export const OPPORTUNITY_NOTE_ACCESS_FORBIDDEN =
  'OPPORTUNITY_NOTE_ACCESS_FORBIDDEN';
export const EVALUATION_NOT_WELL_FORMATTED_ERROR =
  'EVALUATION_NOT_WELL_FORMATTED_ERROR';
export const LINKEDIN_URL_INVALID = 'LINKEDIN_URL_INVALID';
export const PROPOSAL_PAY_AS_YOU_GO_WITH_MISSING_FIELDS =
  'PROPOSAL_PAY_AS_YOU_GO_WITH_MISSING_FIELDS';
export const USER_BANKING_NOT_FOUND = 'USER_BANKING_NOT_FOUND';
export const USER_BANKING_WALLET_NOT_FOUND = 'USER_BANKING_WALLET_NOT_FOUND';
export const USER_BANKING_ACCOUNT_NOT_FOUND = 'USER_BANKING_ACCOUNT_NOT_FOUND';
export const USER_BANKING_INSUFFICENT_FUNDS = 'USER_BANKING_INSUFFICENT_FUNDS';
export const PRESHORTLIST_NOT_FOUND = 'PRESHORTLIST_NOT_FOUND';
export const USER_COMPANY_REGION_MISSING = 'USER_COMPANY_REGION_MISSING';
export const FEE_INVOICE_ALREADY_EXISTS_ERROR =
  'FEE_INVOICE_ALREADY_EXISTS_ERROR';
export const OPPORTUNITY_MEMBERS_EMPTY = 'OPPORTUNITY_MEMBERS_EMPTY';
export const OPPORTUNITY_APPLICATION_PRICING_INFO_INVALID =
  'OPPORTUNITY_APPLICATION_PRICING_INFO_INVALID';
export const USER_NOT_IN_NETWORK = 'USER_NOT_IN_NETWORK';
export const RECRUITER_NOT_FOUND = 'RECRUITER_NOT_FOUND';
export const RECRUITER_NOTE_NOT_FOUND = 'RECRUITER_NOTE_NOT_FOUND';
export const RECRUITER_LIST_NOT_FOUND = 'RECRUITER_LIST_NOT_FOUND';
export const RECRUITER_FREE_TRIAL_ALREADY_USED =
  'RECRUITER_FREE_TRIAL_ALREADY_USED';
export const INVALID_RECRUITER_PLAN = 'INVALID_RECRUITER_PLAN';
export const PLACES_API = 'PLACES_API';
export const SAVED_SEARCH_NOT_FOUND = 'SAVED_SEARCH_NOT_FOUND';
export const APPROVAL_NOT_FOUND = 'APPROVAL_NOT_FOUND';
export const APPROVAL_EXPIRED = 'APPROVAL_EXPIRED';
