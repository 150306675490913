import {
  companyName,
  email,
  firstName,
  image,
  jobTitle,
  lastName,
  linkedInUrls,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_ApprovalType } from '../../generated';

export const generateApproval = (
  props?: Partial<Collective_ApprovalType>,
  n = 0
): Collective_ApprovalType => {
  return {
    id: cuid(),
    companyName: companyName[n],
    email: email[n],
    firstname: firstName[n],
    lastname: lastName[n],
    linkedInUrl: linkedInUrls[n],
    order: n,
    title: jobTitle[n],
    pictureUrl: image[n],
    companyLogoUrl: image[n],
    approvedDate: new Date().toISOString(),
    lastEmailSentAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
    ...props,
  };
};
