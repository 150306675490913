export const ROOT = '/';

// AUTH
export const ROOT_AUTH = '/auth';
export const COLLECTIVE_LOGIN = 'login';
export const SEND_RESET = 'send-reset';
export const SEND_RESET_CONFIRMATION = 'send-reset-confirmation';
export const SEND_CONFIRMATION = 'send-confirmation';
export const RESET = 'reset-password';
export const CREATE_ACCOUNT = 'create-account';
export const SIGNUP = 'signup';
export const SHARE_OPPORTUNITY_SIGNUP = 'share-opportunity-signup';
export const SHARE_OPPORTUNITY_ID_PARAM = ':opportunityShareId';

// CLIENT-AREA
export const CLIENT = 'client';
export const CLIENT_HOME = 'home';
export const CLIENT_TRANSACTIONS = 'transactions';
export const CLIENT_PROJECT = 'project';
export const CLIENT_LOGIN = 'login';

export const CLIENT_OVERVIEW_MISSIONS = 'overview-missions';
export const CLIENT_ENDED_MISSIONS = 'ended-missions';
export const CLIENT_TRANSACTION_OVERVIEW = 'overview';
export const CLIENT_TRANSACTION_UPCOMING_PAYMENTS = 'upcoming-payments';

export const ROOT_CLIENT = `/${CLIENT}`;

export const FULLPATH_CLIENT_LOGIN = `${ROOT_CLIENT}/${CLIENT_LOGIN}`;

// PUBLIC-AREA
export const ROOT_PUBLIC = '/public';

// RECRUITER-AREA
export const ROOT_RECRUITER = '/recruiter';

// CONFIRM EMAIL
export const ROOT_CONFIRM_EMAIL = '/confirm';

// CONFIRM IBAN
export const ROOT_CONFIRM_IBAN = '/confirm-iban';

// NOTIFICATIONS
export const ROOT_NOTIFICATIONS = '/notifications';
export const ROOT_UNSUBSCRIBED = '/unsubscribed';

// INVITATION
export const ROOT_JOIN = 'join';
export const INVITE = `${ROOT_JOIN}/invite/:token`;

// PROFILING QUESTIONS
export const ROOT_PROFILING_QUESTIONS = '/profiling-questions';

// ONBOARDING
export const ROOT_ONBOARDING = '/onboarding';
export const ONBOARDING_COMPLETED = 'completed';
export const ONBOARDING_USER_INFORMATION = 'user-information';
export const ONBOARDING_BANKING_INFORMATION = 'banking-information';
export const ONBOARDING_HAS_LEGAL_STRUCTURE = 'has-legal-structure';
export const ONBOARDING_LEGAL_STRUCTURE_COUNTRY = 'legal-structure-country';
export const ONBOARDING_LEGAL_STRUCTURE_KIND = 'legal-structure-kind';
export const ONBOARDING_LEGAL_STRUCTURE_LOOKUP = 'legal-structure-lookup';
export const ONBOARDING_LEGAL_STRUCTURE_DETAILS = 'legal-structure-details';

export const FULLPATH_ONBOARDING_USER_INFORMATION = `${ROOT_ONBOARDING}/${ONBOARDING_USER_INFORMATION}`;
export const FULLPATH_ONBOARDING_HAS_LEGAL_STRUCTURE = `${ROOT_ONBOARDING}/${ONBOARDING_HAS_LEGAL_STRUCTURE}`;
export const FULLPATH_ONBOARDING_BANKING_INFORMATION = `${ROOT_ONBOARDING}/${ONBOARDING_BANKING_INFORMATION}`;

// PARAMS
export const PROJECT_ID_PARAM = ':projectId';
export const OPPORTUNITY_ID_PARAM = ':opportunityId';
export const PROPOSAL = 'proposal';
export const PROPOSAL_ID_PARAM = ':proposalId';
export const PAYMENT_REQUEST_ID_PARAM = ':paymentRequestId';
export const PRESHORTLIST_ID_PARAM = ':preshortlistId';
export const CHANNEL_ID_PARAM = ':channelId';
export const CHAT_USER_ID_PARAM = ':userId';

export const SIGNUP_EMAIL_PARAMETER_KEY = 'email';

// QUERY PARAMS
export const TOAST_TITLE = 'toast_title';
export const TOAST_DESCRIPTION = 'toast_description';
export const TOAST_TYPE = 'toast_type';

// PUBLIC MODULE
export const PUBLIC_LOGIN = 'login';

// PUBLIC PAYMENT FUNNEL
export const PAYMENT_FUNNEL = 'payment-funnel';
export const PAYMENT_FUNNEL_CARD = 'credit-card';
export const PAYMENT_FUNNEL_BANK_TRANSFER = 'bank-transfer';
export const PAYMENT_FUNNEL_CARD_ID_PARAM = ':cardId';
export const PAYMENT_FUNNEL_CALLBACK = 'callback';
export const PAYMENT_FUNNEL_SUCCESS = 'success';

export const FULLPATH_PAYMENT_FUNNEL_ROOT = `${ROOT_PUBLIC}/${PAYMENT_FUNNEL}/${PAYMENT_REQUEST_ID_PARAM}`;
export const FULLPATH_PAYMENT_FUNNEL_CARD = `${ROOT_PUBLIC}/${PAYMENT_FUNNEL}/${PAYMENT_REQUEST_ID_PARAM}/${PAYMENT_FUNNEL_CARD}`;
export const FULLPATH_PAYMENT_FUNNEL_BANK_TRANSFER = `${ROOT_PUBLIC}/${PAYMENT_FUNNEL}/${PAYMENT_REQUEST_ID_PARAM}/${PAYMENT_FUNNEL_BANK_TRANSFER}`;
export const FULLPATH_PAYMENT_FUNNEL_CALLBACK = `${ROOT_PUBLIC}/${PAYMENT_FUNNEL}/${PAYMENT_REQUEST_ID_PARAM}/${PAYMENT_FUNNEL_CARD_ID_PARAM}/${PAYMENT_FUNNEL_CALLBACK}`;
export const FULLPATH_PAYMENT_FUNNEL_SUCCESS = `${ROOT_PUBLIC}/${PAYMENT_FUNNEL}/${PAYMENT_REQUEST_ID_PARAM}/${PAYMENT_FUNNEL_SUCCESS}`;

// CLIENT - SHORTLIST
export const SHORTLIST = 'shortlist';
export const SHORTLIST_LANDING = 'landing';
export const SHORTLIST_SELECTION = 'selection';

// CLIENT - PAYMENT-REQUEST
export const PAYMENT_REQUEST = 'payment-request';

// CLIENT - REVIEWS
export const POST_MISSION_REVIEW = 'post-mission-review';

// CLIENT INTERFACE
export const FULLPATH_CLIENT_PROJECT = `${ROOT_CLIENT}/${CLIENT_PROJECT}/${PROJECT_ID_PARAM}`;
export const FULLPATH_CLIENT_INTERFACE_SHORTLIST = `${FULLPATH_CLIENT_PROJECT}/${SHORTLIST}`;
export const FULLPATH_CLIENT_INTERFACE_PROPOSAL = `${FULLPATH_CLIENT_PROJECT}/${PROPOSAL}/${PROPOSAL_ID_PARAM}`;
export const FULLPATH_CLIENT_INTERFACE_PAYMENT_REQUEST = `${FULLPATH_CLIENT_PROJECT}/${PAYMENT_REQUEST}/${PAYMENT_REQUEST_ID_PARAM}`;
export const FULLPATH_CLIENT_HOME = `${ROOT_CLIENT}/${CLIENT_HOME}`;
export const FULLPATH_CLIENT_TRANSACTIONS = `${ROOT_CLIENT}/${CLIENT_TRANSACTIONS}`;
export const FULLPATH_CLIENT_TRANSACTIONS_OVERVIEW = `${FULLPATH_CLIENT_TRANSACTIONS}/${CLIENT_TRANSACTION_OVERVIEW}`;
export const FULLPATH_CLIENT_TRANSACTIONS_UPCOMING_PAYMENT_REQUESTS = `${FULLPATH_CLIENT_TRANSACTIONS}/${CLIENT_TRANSACTION_UPCOMING_PAYMENTS}`;
export const FULLPATH_CLIENT_POST_MISSION_REVIEW = `${FULLPATH_CLIENT_PROJECT}/${POST_MISSION_REVIEW}`;

// RECRUITER - AUTH
export const RECRUITER_AUTH = 'auth';
export const RECRUITER_SIGNUP = 'signup';
export const RECRUITER_LOGIN = 'login';
export const RECRUITER_RESET_PASSWORD = 'reset-password';
export const RECRUITER_MESSAGES = 'messages';
export const RECRUITER_JOBS = 'jobs';
export const RECRUITER_JOBS_FORM = 'create';
export const RECRUITER_JOB_ID_PARAM = ':jobId';

export const RECRUITER_APPROVAL_LANDING = 'approval-landing';
export const RECRUITER_APPROVAL_HASH_PARAM = ':approvalHash';
export const RECRUITER_APPROVAL_HASH_QUERY_PARAM =
  RECRUITER_APPROVAL_HASH_PARAM.substring(1);

// RECRUITER - SEARCH
export const RECRUITER_SEARCH = 'search';
export const RECRUITER_SAVED_SEARCH = 'saved-search';
export const RECRUITER_ONE_SEARCH = 'one-search';
export const RECRUITER_SEARCH_PROFILE_ID_PARAM = 'profileId';

// RECRUITER - IMPORT USERS
export const RECRUITER_IMPORT_USERS = 'import-users';

// RECRUITER - LISTS
export const RECRUITER_LISTS = 'lists';
export const RECRUITER_LIST_ID_PARAM = ':listId';

// RECRUITER - SETTINGS
export const RECRUITER_SETTINGS = 'settings';

// RECRUITER PATHS
export const FULLPATH_RECRUITER_AUTH_LOGIN = `${ROOT_RECRUITER}/${RECRUITER_AUTH}/${RECRUITER_LOGIN}`;
export const FULLPATH_RECRUITER_AUTH_SIGNUP = `${ROOT_RECRUITER}/${RECRUITER_AUTH}/${RECRUITER_SIGNUP}`;
export const FULLPATH_RECRUITER_AUTH_RESET_PASSWORD = `${ROOT_RECRUITER}/${RECRUITER_AUTH}/${RECRUITER_RESET_PASSWORD}`;
export const FULLPATH_RECRUITER_APPROVAL_LANDING = `${ROOT_RECRUITER}/${RECRUITER_APPROVAL_LANDING}/${RECRUITER_APPROVAL_HASH_PARAM}`;
export const FULLPATH_RECRUITER_SAVED_SEARCH = `${ROOT_RECRUITER}/${RECRUITER_SAVED_SEARCH}`;
export const FULLPATH_RECRUITER_ONE_SEARCH = `${ROOT_RECRUITER}/${RECRUITER_ONE_SEARCH}`;
export const FULLPATH_RECRUITER_SEARCH = `${ROOT_RECRUITER}/${RECRUITER_SEARCH}`;
export const FULLPATH_RECRUITER_JOBS = `${ROOT_RECRUITER}/${RECRUITER_JOBS}`;
export const FULLPATH_RECRUITER_JOB = `${ROOT_RECRUITER}/${RECRUITER_JOBS}/${RECRUITER_JOB_ID_PARAM}`;
export const FULLPATH_RECRUITER_JOBS_FORM = `${FULLPATH_RECRUITER_JOBS}/${RECRUITER_JOBS_FORM}`;
export const FULLPATH_RECRUITER_MESSAGES = `${ROOT_RECRUITER}/${RECRUITER_MESSAGES}`;
export const FULLPATH_RECRUITER_CHANNEL = `${FULLPATH_RECRUITER_MESSAGES}/${CHANNEL_ID_PARAM}`;
export const FULLPATH_RECRUITER_SETTINGS = `${ROOT_RECRUITER}/${RECRUITER_SETTINGS}`;
export const FULLPATH_RECRUITER_IMPORT_USERS = `${ROOT_RECRUITER}/${RECRUITER_IMPORT_USERS}`;
export const FULLPATH_RECRUITER_LISTS = `${ROOT_RECRUITER}/${RECRUITER_LISTS}`;
export const FULLPATH_RECRUITER_LIST = `${FULLPATH_RECRUITER_LISTS}/${RECRUITER_LIST_ID_PARAM}`;

// COLLECTIVE-AREA
export const MY_COLLECTIVE = 'my-collective';
export const MY_PERSONAL_SPACE = 'my-personal-space';
export const COLLECTIVE = 'collective';
export const COLLECTIVE_SLUG_PARAM = ':collectiveSlug';

export const PROJECTS = 'projects'; // Legacy path, kept for redirection
export const INBOX = 'inbox'; // Legacy path, kept for redirection
export const PROJECTS_ACTIVITY = 'projects-activity';
export const OPPORTUNITIES = 'opportunities';

export const PRESHORTLIST = 'presentation';
export const MISSIONS = 'missions';
export const OVERVIEW = 'overview';
export const ACTIVITY = 'activity';
export const ONGOING = 'ongoing';
export const COMPLETED = 'completed';
export const MEMBERS = 'members';
export const CLIENTS = 'clients';
export const ARCHIVED = 'archived';
export const ENDED_MISSIONS = 'ended-missions';
export const EXTEND = 'extend';
export const FEE = 'fee';

export const CONTRACT_CREATE = 'contract-v2';
export const MISSION_ID_PARAM = ':missionId';

// COLLECTIVE-AREA - PERSONAL SPACE
export const HOME = 'home';
export const ONBOARD = 'get-onboard';
export const PROFILE = 'profile';
export const PORTFOLIO = 'portfolio';

// COLLECTIVE-AREA > FINANCE
export const FINANCE = 'finance';
export const TRANSACTIONS = 'transactions';

// COLLECTIVE_PAGE_MANAGER
export const PAGE_MANAGER = 'page';
export const PAGE_MANAGER_SETUP = 'setup';
export const GENERAL_INFORMATION = 'infos';
export const PAGE_MANAGER_MEMBERS = 'members';
export const CASE_STUDIES = 'case-studies';
export const CASE_STUDY = 'case-study';
export const CASE_STUDY_ID_PARAM = ':caseStudyId';

// NETWORK
export const NETWORK = 'network';
export const MESSAGES = 'messages';
export const FEED = 'feed';
export const NETWORK_INVITATION = 'network-invitation';
export const NETWORK_INVITATION_PARAM = ':collectiveSlug';

export const NETWORK_INVITATION_SLUG_PARAMETER_KEY = 'invitationSlug';
export const NETWORK_INVITATION_ID_PARAMETER_KEY = 'invitationUserId';

// SETTINGS
export const SETTINGS = 'settings';
export const SETTINGS_GENERAL = 'general';
export const SETTINGS_COMPANY_INFORMATION = 'company-information';
export const SETTINGS_BANKING = 'banking';
export const SETTINGS_SECURITY = 'security';
export const SETTINGS_APP_PREFERENCES = 'app-preferences';

// INVOICES
export const INVOICES = 'invoices';

// PRODUCT
export const PRODUCT = 'products';

// EXCLUSIVE LEADS
export const EXCLUSIVE_LEADS = 'exclusive-leads';

export const PRODUCT_NO_CONTRACT = 'no-contract';

// QUOTES
export const QUOTES = 'quotes';
export const NEW_QUOTE = 'quote/new';

// JOBS
export const JOBS = 'jobs';

// SEARCH
export const SEARCH = 'search';
export const FULLPATH_SEARCH = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${SEARCH}`;

// SHARE TINY URL
export const ROOT_SHARE = '/share';
export const SHARE_HASH_PARAM = ':hash';
export const FULLPATH_SHARE = `${ROOT_SHARE}/${SHARE_HASH_PARAM}`;

// ADMIN
export const ADMIN = 'admin';
export const ROOT_ADMIN = `/${ADMIN}`;
export const FULLPATH_ADMIN_SHORTLIST = `${ROOT_ADMIN}/${SHORTLIST}/${PROJECT_ID_PARAM}`;
export const ADMIN_OPPORTUNITY = 'opportunity';
export const VIEW = 'view';
export const FULLPATH_ADMIN_PROJECT_SHORTLIST_OPPORTUNITY = `${FULLPATH_ADMIN_SHORTLIST}/${ADMIN_OPPORTUNITY}/${OPPORTUNITY_ID_PARAM}`;
export const FULLPATH_ADMIN_PROJECT_SHORTLIST_OPPORTUNITY_VIEW = `${FULLPATH_ADMIN_SHORTLIST}/${ADMIN_OPPORTUNITY}/${OPPORTUNITY_ID_PARAM}/${VIEW}`;

/**
 * BACKEND ROUTES
 * ⚠️ WARNING: careful when changing this
 */

// auth/
export const FULLPATH_RESET = `${ROOT_AUTH}/${RESET}`;
export const FULLPATH_SIGNUP_FORM = `${ROOT_AUTH}/${SIGNUP}`;
export const FULLPATH_LOGIN = `${ROOT_AUTH}/${COLLECTIVE_LOGIN}`;
export const FULLPATH_CREATE_ACCOUNT = `${ROOT_AUTH}/${CREATE_ACCOUNT}`;

// onboarding/
export const FULLPATH_ONBOARDING_START = `${ROOT_ONBOARDING}/${ONBOARDING_USER_INFORMATION}`;

// collective/
export const PATH_COLLECTIVE = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}`;
export const PATH_FINANCE = `${PATH_COLLECTIVE}/${FINANCE}`;
export const PATH_PRODUCT = `${PATH_COLLECTIVE}/${PRODUCT}`;
export const PATH_PROJECTS_ACTIVITY = `${PATH_COLLECTIVE}/${PROJECTS_ACTIVITY}`;

// personal space
export const PATH_ONBOARD = `${PATH_COLLECTIVE}/${ONBOARD}`;
export const PATH_HOME = `${PATH_COLLECTIVE}/${HOME}`;
export const PATH_PROFILE = `${PATH_COLLECTIVE}/${PROFILE}`;
export const PATH_INVOICES = `${PATH_COLLECTIVE}/${INVOICES}`;
export const PATH_SETTINGS = `${PATH_COLLECTIVE}/${SETTINGS}`;
export const PATH_PORTFOLIO = `${PATH_PROFILE}/${PORTFOLIO}`;
export const PATH_PORTFOLIO_EDIT = `${PATH_PROFILE}/${PORTFOLIO}/${CASE_STUDY_ID_PARAM}`;

export const FULLPATH_PERSONAL_SPACE_NETWORK = `/${MY_PERSONAL_SPACE}/${NETWORK}`;
export const PERSONAL_SPACE_PATH_PROJECTS_ACTIVITY = `/${MY_PERSONAL_SPACE}/${PROJECTS_ACTIVITY}`;
export const PERSONAL_SPACE_PATH_PROFILE = `/${MY_PERSONAL_SPACE}/${PROFILE}`;

export const PROFILE_TAB_QUERY_PARAM = 'tab';

// products
export const PATH_PRODUCT_NO_CONTRACT = `${PATH_PRODUCT}/${PRODUCT_NO_CONTRACT}`;

// quotes
export const PATH_QUOTES_OVERVIEW = `${PATH_COLLECTIVE}/${QUOTES}`;

// jobs
export const PATH_JOBS_OVERVIEW = `${PATH_COLLECTIVE}/${JOBS}`;

// search
export const PATH_SEARCH_OVERVIEW = `${PATH_COLLECTIVE}/${SEARCH}`;
export const FULLPATH_PERSONAL_SPACE_SEARCH_OVERVIEW = `/${MY_PERSONAL_SPACE}/${SEARCH}`;

// Legacy path, kept for redirection
export const FULLPATH_INBOX_PROJECT = `${PATH_COLLECTIVE}/${PROJECTS}/${INBOX}/${PROJECT_ID_PARAM}`;

export const FULLPATH_SHARE_OPPORTUNITY_SIGNUP = `${ROOT_AUTH}/${SHARE_OPPORTUNITY_SIGNUP}/${SHARE_OPPORTUNITY_ID_PARAM}`;
export const FULLPATH_NETWORK_INVITATION = `/${NETWORK_INVITATION}/${NETWORK_INVITATION_PARAM}`;

export const FULLPATH_CHANNEL_REDIRECT = `/chat/user/${CHAT_USER_ID_PARAM}`;

export const FULLPATH_MEMBERS = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${MEMBERS}`;
export const FULLPATH_CLIENTS = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${CLIENTS}`;
export const FULLPATH_COLLECTIVE_PAGE_MANAGER = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${PAGE_MANAGER}`;
export const FULLPATH_COLLECTIVE_PAGE_MANAGER_GENERAL = `${FULLPATH_COLLECTIVE_PAGE_MANAGER}/${GENERAL_INFORMATION}`;
export const FULLPATH_COLLECTIVE_PAGE_MANAGER_SETUP = `${FULLPATH_COLLECTIVE_PAGE_MANAGER}/${PAGE_MANAGER_SETUP}`;
export const FULLPATH_COLLECTIVE_PAGE_MANAGER_CASE_STUDIES = `${FULLPATH_COLLECTIVE_PAGE_MANAGER}/${CASE_STUDIES}`;
export const FULLPATH_COLLECTIVE_PAGE_MANAGER_MEMBERS = `${FULLPATH_COLLECTIVE_PAGE_MANAGER}/${MEMBERS}`;
export const FULLPATH_CASE_STUDY_EDIT = `${FULLPATH_COLLECTIVE_PAGE_MANAGER}/${CASE_STUDY}/${CASE_STUDY_ID_PARAM}`;
export const FULLPATH_PROJECT_ACTIVITY_OPPORTUNITIES_OVERVIEW = `${PATH_PROJECTS_ACTIVITY}/${OPPORTUNITIES}/${OVERVIEW}`;
export const FULLPATH_PROJECT_ACTIVITY_OPPORTUNITY = `${PATH_PROJECTS_ACTIVITY}/${OPPORTUNITIES}/${PROJECT_ID_PARAM}`;
export const FULLPATH_PROJECT_ACTIVITY_OPPORTUNITY_OVERVIEW = `${PATH_PROJECTS_ACTIVITY}/${OPPORTUNITIES}/${PROJECT_ID_PARAM}/${OVERVIEW}`;
export const FULLPATH_PERSONAL_SPACE_PROJECT_ACTIVITY_OPPORTUNITY = `${PERSONAL_SPACE_PATH_PROJECTS_ACTIVITY}/${OPPORTUNITIES}/${PROJECT_ID_PARAM}`;
export const FULLPATH_MISSION_OVERVIEW = `${PATH_PROJECTS_ACTIVITY}/${MISSIONS}/${MISSION_ID_PARAM}/${OVERVIEW}`;
export const FULLPATH_MISSION_EXTEND = `${PATH_PROJECTS_ACTIVITY}/${MISSIONS}/${MISSION_ID_PARAM}/${EXTEND}`;
export const FULLPATH_CONTRACT = `${FULLPATH_PROJECT_ACTIVITY_OPPORTUNITY}/${CONTRACT_CREATE}`;
export const FULLPATH_NEW_QUOTE = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${NEW_QUOTE}`;
export const FULLPATH_PROJECT_ACTIVITY_OPPORTUNITY_DRAFT_PROPOSAL = `${FULLPATH_PROJECT_ACTIVITY_OPPORTUNITY}/${PROPOSAL}/${PROPOSAL_ID_PARAM}`;

export const FULLPATH_MESSAGES = `/${COLLECTIVE}/${COLLECTIVE_SLUG_PARAM}/${MESSAGES}`;
export const FULLPATH_MESSAGES_CHANNEL = `${FULLPATH_MESSAGES}/${CHANNEL_ID_PARAM}`;
// messages with my-personal-space to have generic URLs for anybody with access to the channel
export const FULLPATH_MY_MESSAGES_CHANNEL = `/${MY_PERSONAL_SPACE}/${MESSAGES}/${CHANNEL_ID_PARAM}`;

// settings
export const FULLPATH_SETTINGS_GENERAL = `${PATH_SETTINGS}/${SETTINGS_GENERAL}`;
export const FULLPATH_SETTINGS_COMPANY_INFORMATION = `${PATH_SETTINGS}/${SETTINGS_COMPANY_INFORMATION}`;
export const FULLPATH_SETTINGS_BANKING = `${PATH_SETTINGS}/${SETTINGS_BANKING}`;
export const FULLPATH_SETTINGS_SECURITY = `${PATH_SETTINGS}/${SETTINGS_SECURITY}`;
export const FULLPATH_SETTINGS_APP_PREFERENCES = `${PATH_SETTINGS}/${SETTINGS_APP_PREFERENCES}`;
// settings with the my-personal-space hack (for email links when sent to many users at once or for components without the useCurrentCollective context)
export const FULLPATH_MY_SETTINGS_GENERAL = `/${MY_PERSONAL_SPACE}/${SETTINGS}/${SETTINGS_GENERAL}`;
export const FULLPATH_MY_SETTINGS_BANKING = `/${MY_PERSONAL_SPACE}/${SETTINGS}/${SETTINGS_BANKING}`;
export const FULLPATH_MY_SETTINGS_APP_PREFERENCES = `/${MY_PERSONAL_SPACE}/${SETTINGS}/${SETTINGS_APP_PREFERENCES}`;

// personal space
export const FULLPATH_PERSONAL_SPACE = `${ROOT}${MY_PERSONAL_SPACE}`;

// personal space paths
export const FULLPATH_PERSONAL_SPACE_PROFILE = `${FULLPATH_PERSONAL_SPACE}/${PROFILE}`;
export const FULLPATH_PERSONAL_SPACE_INVOICES = `${FULLPATH_PERSONAL_SPACE}/${INVOICES}`;
export const FULLPATH_PERSONAL_SPACE_NETWORK_LIST = `${FULLPATH_PERSONAL_SPACE}/${NETWORK}`;
export const FULLPATH_PERSONAL_SPACE_SEARCH = `${FULLPATH_PERSONAL_SPACE}/${SEARCH}`;

// others
export const FULLPATH_CONFIRM_EMAIL = ROOT_CONFIRM_EMAIL;
export const FULLPATH_CONFIRM_IBAN = ROOT_CONFIRM_IBAN;

// invitation
export const FULLPATH_INVITE = INVITE;

// public opportunity
export const FULLPATH_PUBLIC_OPPORTUNITY = `${ROOT_PUBLIC}/${OPPORTUNITIES}/${OPPORTUNITY_ID_PARAM}`;

// public preshortlist
export const FULLPATH_PRESHORTLIST = `${ROOT_PUBLIC}/${PRESHORTLIST}/${PRESHORTLIST_ID_PARAM}`;
